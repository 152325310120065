import { VueCookieNext } from 'vue-cookie-next';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/store';
import { LAYOUTS, PERMISSIONS, TENANT_SETTINGS } from '@/enum';
import { Assessment, Calendar, Dashboard, Organizations, Profile, Users } from '@/views';
import Routes from './routes';

const publicRoutes: string[] = [
  '/account/activate',
  '/account/login',
  '/account/logout',
  '/account/forgot-password',
  '/account/reset-password',
  '/legal/privacy-policy',
  '/legal/terms-of-service',
  '/account/verify-token-expired',
  '/404',
];

export enum USER_ROUTE {
  PROFILE = '',
  CONNECTIONS = 'connections',
  TREATMENT_PLANS = 'treatment-plans',
  ACTIVITY = 'activity',
  DOCUMENTS = 'documents',
  IMPERSONATE = 'impersonate',
  IMPORT = 'import',
  PROGRESS_REPORT = 'progress-report',
}

const routes: Array<RouteRecordRaw> = [
  ...Routes,
  {
    path: '/',
    redirect: '/dashboard/overview',
    name: 'dashboard',
    component: Dashboard,
    meta: { layout: LAYOUTS.DEFAULT, title: 'Dashboard - eLuma Insight' },
    children: [
      {
        path: '/dashboard/payment',
        component: () =>
          import(/* webpackChunkName: "dashboard.payment" */ '@/views/Dashboard/components/Payment/Payment.vue'),
      },

      {
        path: '/dashboard/time-usage',
        component: () =>
          import(/* webpackChunkName: "dashboard.time-usage" */ '@/views/Dashboard/components/TimeUsage/TimeUsage.vue'),
        meta: {
          layout: LAYOUTS.DEFAULT,
          title: 'Time Usage - eLuma Insight',
          permission: [PERMISSIONS.PAYMENT_ME],
        },
      },

      {
        path: '/dashboard/activity',
        component: () =>
          import(/* webpackChunkName: "dashboard.activity" */ '@/views/Dashboard/components/Activity/Activity.vue'),
        meta: {
          layout: LAYOUTS.DEFAULT,
          permission: [PERMISSIONS.DASHBOARD_ACTIVITY_INDEX],
        },
      },
      {
        path: 'dashboard/overview',
        meta: { layout: LAYOUTS.DEFAULT, title: 'Dashboard - eLuma Insight' },
        component: () =>
          import(/* webpackChunkName: "dashboard.overview" */ '@/views/Dashboard/components/Overview/Overview.vue'),
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { layout: LAYOUTS.DEFAULT, title: 'Profile - eLuma Insight' },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { layout: LAYOUTS.DEFAULT, title: 'Calendar - eLuma Insight', permission: [PERMISSIONS.MENU_CALENDAR_VIEW] },
    children: [
      {
        path: '',
        name: 'calendar-list',
        component: () => import(/* webpackChunkName: "calendar.list" */ '@/views/Calendar/components/CalendarList.vue'),
      },
      {
        path: 'day',
        component: () => import(/* webpackChunkName: "calendar.day" */ '@/views/Calendar/components/CalendarDay.vue'),
      },
      {
        path: 'day/:date',
        component: () => import(/* webpackChunkName: "calendar.day" */ '@/views/Calendar/components/CalendarDay.vue'),
      },
      {
        path: 'week',
        component: () => import(/* webpackChunkName: "calendar.week" */ '@/views/Calendar/components/CalendarWeek.vue'),
      },
      {
        path: 'month',
        component: () =>
          import(/* webpackChunkName: "calendar.month" */ '@/views/Calendar/components/CalendarMonth.vue'),
      },
    ],
  },
  {
    path: '/assessment-pdf-viewer',
    name: 'assessment.pdf-viewer',
    component: Assessment,
    meta: { layout: null, title: 'Assessment PDFViewer - eLuma Insight' },
  },

  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      layout: LAYOUTS.DEFAULT,
      title: 'Users - eLuma Insight',
      permission: [PERMISSIONS.PEOPLE_INDEX, PERMISSIONS.PEOPLE_INDEX_CLINICIANS],
    },
    children: [
      {
        path: '',
        name: 'user.list',
        component: () => import(/* webpackChunkName: "users.list" */ '@/views/Users/components/UserList'),
        meta: {
          layout: LAYOUTS.DEFAULT,
          permission: [PERMISSIONS.PEOPLE_INDEX, PERMISSIONS.PEOPLE_INDEX_CLINICIANS],
        },
      },
      {
        path: 'referrals',
        name: 'user.referrals',
        component: () => import(/* webpackChunkName: "users.referrals" */ '@/views/Users/components/UserReferrals'),
        meta: {
          layout: LAYOUTS.DEFAULT,
          permission: [PERMISSIONS.REFERRAL_ASSIGN],
          settings: TENANT_SETTINGS.REFERRALS,
        },
      },
      {
        path: '/user/:id',
        name: 'user',
        component: () => import(/* webpackChunkName: "users.user" */ '@/views/Users/components/User'),
        meta: {
          layout: LAYOUTS.DEFAULT,
        },
        children: [
          {
            path: '',
            name: 'user.profile',
            component: () => import(/* webpackChunkName: "users.profile" */ '@/views/Users/components/UserProfile'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.PEOPLE_SHOW],
            },
          },
          {
            path: USER_ROUTE.CONNECTIONS,
            name: 'user.connections',
            component: () => import(/* webpackChunkName: "users.connections" */ '@/views/Users/components/Connections'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.CONNECTIONS_INDEX],
            },
          },
          {
            path: USER_ROUTE.ACTIVITY,
            name: 'user.activity',
            component: () => import(/* webpackChunkName: "users.activity" */ '@/views/Users/components/Activity'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [
                PERMISSIONS.PERSON_LOGS_INDEX,
                PERMISSIONS.PERSON_LOGS_INDEX_CLINICIANS,
                PERMISSIONS.PERSON_LOGS_INDEX_FACILITATOR,
                PERMISSIONS.PERSON_LOGS_INDEX_STUDENTS,
              ],
            },
          },
          {
            path: USER_ROUTE.DOCUMENTS,
            name: 'user.documents',
            component: () => import(/* webpackChunkName: "users.documents" */ '@/views/Users/components/Documents'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [
                PERMISSIONS.PERSON_DOCUMENTS_INDEX,
                PERMISSIONS.PERSON_DOCUMENTS_INDEX_CLINICIANS,
                PERMISSIONS.PERSON_DOCUMENTS_INDEX_FACILITATORS,
                PERMISSIONS.PERSON_DOCUMENTS_INDEX_STUDENTS,
              ],
            },
          },
          {
            path: USER_ROUTE.PROGRESS_REPORT,
            name: 'user.progress',
            component: () => import(/* webpackChunkName: "users.progress" */ '@/views/Users/components/ProgressReport'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX, PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX_STUDENTS],
            },
          },
          {
            path: USER_ROUTE.TREATMENT_PLANS,
            name: 'user.treatmentPlans',
            component: () =>
              import(/* webpackChunkName: "users.treatmentPlans" */ '@/views/Users/components/TreatmentPlans'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX, PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX_STUDENTS],
            },
          },
        ],
      },
    ],
  },

  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
    meta: {
      layout: LAYOUTS.DEFAULT,
      title: 'Organizations - eLuma Insight',
      permission: [PERMISSIONS.ORGANIZATIONS_INDEX],
    },
    children: [
      {
        path: '',
        name: 'organization.list',
        component: () => import(/* webpackChunkName: "organization.list" */ '@/views/Organizations/OrganizationList'),
        meta: {
          layout: LAYOUTS.DEFAULT,
          permission: [PERMISSIONS.ORGANIZATIONS_INDEX],
        },
      },
      {
        path: ':id',
        name: 'organization',
        component: () => import(/* webpackChunkName: "organization.user" */ '@/views/Organizations/Organization'),
        meta: {
          layout: LAYOUTS.DEFAULT,
          permission: [PERMISSIONS.ORGANIZATIONS_CONNECTIONS],
        },
        children: [
          {
            path: '',
            name: 'organization.connections',
            component: () =>
              import(/* webpackChunkName: "organization.conections" */ '@/views/Organizations/Connections'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.ORGANIZATIONS_CONNECTIONS],
            },
          },
          {
            path: 'documents',
            name: 'organization.documents',
            component: () =>
              import(/* webpackChunkName: "organization.documents" */ '@/views/Organizations/OrganizationDocuments'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.ORGANIZATION_DOCUMENTS_SHOW],
            },
          },
          {
            path: 'profile',
            name: 'organization.profile',
            component: () => import(/* webpackChunkName: "organization.profile" */ '@/views/Organizations/Profile'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.ORGANIZATION_SHOW],
            },
          },
          {
            path: 'contracts',
            name: 'organization.contract',
            component: () =>
              import(/* webpackChunkName: "organization.contracts" */ '@/views/Organizations/OrganizationContracts'),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.CONTRACTS_SHOW],
            },
          },
          {
            path: 'integrations',
            name: 'organization.integrations',
            component: () =>
              import(
                /* webpackChunkName: "organization.integrations" */ '@/views/Organizations/OrganizationIntegrations'
              ),
            meta: {
              layout: LAYOUTS.DEFAULT,
              permission: [PERMISSIONS.ORGANIZATION_DOCUMENTS_SHOW],
            },
          },
          {
            path: 'logs',
            name: 'organization.logs',
            component: () =>
              import(
                /* webpackChunkName: "organization.logs" */ '@/views/Organizations/OrganizationLogs/OrganizationLogs.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/account/login',
    name: 'account.login',
    component: () => import(/* webpackChunkName: "account.login" */ '@/views/Account/Login/Login.vue'),
    meta: { layout: null, title: 'Login - eLuma Insight' },
  },
  {
    path: '/account/logout',
    name: 'account.logout',
    component: () => import(/* webpackChunkName: "account.logout" */ '@/views/Account/Logout'),
  },
  {
    path: '/account/forgot-password',
    name: 'account.forgot-password',
    component: () =>
      import(/* webpackChunkName: "account.forgot-password" */ '@/views/Account/ForgotPassword/ForgotPassword.vue'),
    meta: { layout: null, title: 'Forgot Password - eLuma Insight' },
  },
  {
    path: '/account/reset-password/:token',
    name: 'account.reset-password',
    component: () =>
      import(/* webpackChunkName: "account.reset-password" */ '@/views/Account/ResetPassword/ResetPassword.vue'),
    meta: { layout: null, title: 'Reset Password - eLuma Insight' },
  },
  {
    path: '/account/activate/:token',
    name: 'account.create-password',
    component: () =>
      import(/* webpackChunkName: "account.create-password" */ '@/views/Account/CreatePassword/CreatePassword.vue'),
    meta: { layout: null, title: 'Login - eLuma Insight' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

/**
 * this is called before each route to check for protected pages
 * and redirect if not logged in
 */
router.beforeEach(async (to, from, next) => {
  VueCookieNext.setCookie('last_route', from.path, {
    secure: 'true',
    sameSite: '',
  });

  const authStore = useAuthStore();
  const personType = authStore.personType;
  // cleanedRoute are a method to permit to the user to access to the page without login
  const cleanedRoute = to.path.toLocaleString().split('/').slice(0, 3).join('/');
  const shouldAuth = !authStore.isAuthenticated && !publicRoutes.includes(cleanedRoute);

  if (authStore.isAuthenticated && !personType) {
    await authStore.getAuthUserProfile();
  }

  if (shouldAuth) {
    authStore.redirectURL = to.fullPath;

    if (to.path === '/account/login' && to.query.oauth === '1') {
      next();
    } else {
      const loginPath = '/account/login' + (to.query.oauth === '1' ? '?oauth=1' : '');
      next(loginPath);
    }
  } else {
    next();
  }

  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title || 'eLuma Insight';
  }
});

export { routes };

export default router;
