import { ApiPromise, UtilizationAnalyticsFilterInterface } from '@/types';
import { HTTP } from '@/utils';

export default class UtilizationService {
  static utilizationResource = 'utilization-analytics';

  static getUtilizationData(params: UtilizationAnalyticsFilterInterface): ApiPromise {
    return HTTP.get(`${this.utilizationResource}`, { params });
  }
}
