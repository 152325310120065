import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { TreeNode } from 'primevue/tree';
import { useGroupOrganizations, useFlattenOrganizations } from '@/composables';
import { PersonService } from '@/services';
import { PersonStores } from '@/store';
import { OrganizationInterface, UserConnectionsStoreInterface } from '@/types';

export default defineStore('UserConnectionsStore', {
  state: (): UserConnectionsStoreInterface => ({
    error: '',
    loading: false,
    connections: {
      organizations: [],
      organizationParents: [],
      clinicians: [],
      parents: [],
      disciplines: [],
      students: [],
      facilitators: [],
    },
  }),

  getters: {
    organizations: (state) => useGroupOrganizations(state.connections.organizations),
    flattenOrganizations: (state) => useFlattenOrganizations(state.connections.organizations as TreeNode[]),
    clinicians: (state) => state.connections.clinicians,
    parents: (state) => state.connections.parents,
    disciplines: (state) => state.connections.disciplines,
    students: (state) => state.connections.students,
    facilitators: (state) => state.connections.facilitators,
  },

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    parseOrganizations() {
      let allOrganizations: OrganizationInterface[] = [];

      this.connections.organizations?.forEach((org) => {
        if (org.children.length) allOrganizations = [...allOrganizations, ...org.children];
        else allOrganizations.push(org);
      });
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    async getConnections() {
      this.loading = true;
      try {
        const route = useRoute();
        const usersStore = PersonStores.useUserProfileStore();
        const userId = usersStore.id || route.params.id;
        const response = await PersonService.getConnections(userId as number);
        const data = response.data?.data || {};

        const defaultConnections = {
          organizations: [],
          organizationParents: [],
          clinicians: [],
          parents: [],
          disciplines: [],
          students: [],
          facilitators: [],
        };

        this.connections = {
          ...defaultConnections,
          ...data,
        };
      } catch (error) {
        console.error('Error getting connections:', error);
        // On error, set default empty connections
        this.connections = {
          organizations: [],
          organizationParents: [],
          clinicians: [],
          parents: [],
          disciplines: [],
          students: [],
          facilitators: [],
        };
      } finally {
        this.loading = false;
      }
    },
  },
});
