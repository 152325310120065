import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { UtilizationService } from '@/services';
import { useDateTime, useRouting } from '@/composables';
import { useOrganizationStore, useCustomerSuccessPartnerStore } from '@/store';
import {
  UtilizationAnalyticsInterface,
  SOWReportingTableInterface,
  SOWReportingSPTableInterface,
  TimeRatioReportingTableInterface,
  TimeRatioReportingSPTableInterface,
} from '@/types';

export const useUtilizationStore = defineStore('UtilizationStore', () => {
  const customerSuccessPartnerStore = useCustomerSuccessPartnerStore();
  const organizationStore = useOrganizationStore();
  const $routing = useRouting();

  const loading = ref<boolean>(false);
  const loadingServiceProviderData = ref<boolean>(false);
  const serviceProviderSOWTableData = ref<SOWReportingSPTableInterface[]>([]);
  const serviceProviderTimeRatioTableData = ref<TimeRatioReportingSPTableInterface[]>([]);
  const sOWTableData = ref<SOWReportingTableInterface[]>([]);
  const timeRatioTableData = ref<TimeRatioReportingTableInterface[]>([]);
  const sOWFilterDate = ref<string>(useDateTime().formatDateForApi(new Date()));
  const timeRatioFilterDate = ref<string>(useDateTime().formatDateForApi(new Date()));
  const serviceProviderSOWFilterDate = ref<string>(useDateTime().formatDateForApi(new Date()));
  const serviceProviderTimeRatioFilterDate = ref<string>(useDateTime().formatDateForApi(new Date()));
  const filteredOrganizations = ref<string[]>([]);
  const selectedServiceProviderId = ref<string>('');

  const getUtilizationDashboardWidgetData = async () => {
    loading.value = true;

    const response = await UtilizationService.getUtilizationData({
      end_at: useDateTime().formatDateForApi(new Date()),
      organizations: filteredOrganizations.value,
    });
    sOWTableData.value = generateSOWData(response.data.data);
    timeRatioTableData.value = generateTimeRatioData(response.data.data);
    loading.value = false;
  };

  const getServiceProviderTimeUsageWidgetData = async (serviceProviderId: number) => {
    loadingServiceProviderData.value = true;
    selectedServiceProviderId.value = serviceProviderId.toString();

    const response = await UtilizationService.getUtilizationData({
      end_at: useDateTime().formatDateForApi(new Date()),
      clinicians: [serviceProviderId.toString()],
      organizations: filteredOrganizations.value,
    });
    serviceProviderSOWTableData.value = generateServiceProviderSOWData(response.data.data);
    serviceProviderTimeRatioTableData.value = generateServiceProviderTimeRatioData(response.data.data);
    loadingServiceProviderData.value = false;
  };

  const updateSOWData = async () => {
    const response = await UtilizationService.getUtilizationData({
      end_at: sOWFilterDate.value,
      organizations: filteredOrganizations.value,
    });
    sOWTableData.value = generateSOWData(response.data.data);
  };

  const updateServiceProviderSOWData = async () => {
    const response = await UtilizationService.getUtilizationData({
      end_at: serviceProviderSOWFilterDate.value,
      clinicians: [selectedServiceProviderId.value],
      organizations: filteredOrganizations.value,
    });
    serviceProviderSOWTableData.value = generateServiceProviderSOWData(response.data.data);
  };

  const updateTimeRatioData = async () => {
    const response = await UtilizationService.getUtilizationData({
      end_at: timeRatioFilterDate.value,
      organizations: filteredOrganizations.value,
    });
    timeRatioTableData.value = generateTimeRatioData(response.data.data);
  };

  const updateServiceProviderTimeRatioData = async () => {
    const response = await UtilizationService.getUtilizationData({
      end_at: serviceProviderTimeRatioFilterDate.value,
      clinicians: [selectedServiceProviderId.value],
      organizations: filteredOrganizations.value,
    });
    serviceProviderTimeRatioTableData.value = generateServiceProviderTimeRatioData(response.data.data);
  };

  const generateSOWData = (data: UtilizationAnalyticsInterface[]): SOWReportingTableInterface[] => {
    return data.map(({ clinician, organization, sow_utilization }) => {
      return {
        clinician: clinician,
        organization: organization.name as string,
        sow_utilization: Number(sow_utilization),
      };
    });
  };

  const generateServiceProviderSOWData = (data: UtilizationAnalyticsInterface[]): SOWReportingSPTableInterface[] => {
    return data.map(
      ({
        organization,
        contract,
        contract_id,
        contract_type,
        sow_hours,
        contracted_weeks_elapsed,
        hours_billed,
        sow_utilization,
        average_hours,
      }) => {
        return {
          organization: organization.name as string,
          contract_id,
          contract_quote_number: contract.quote_number,
          contract_type,
          sow_hours: Number(sow_hours),
          contracted_weeks_elapsed,
          hours_billed: Number(hours_billed),
          sow_utilization: Number(sow_utilization),
          average_hours: Number(average_hours),
        };
      },
    );
  };

  const generateTimeRatioData = (data: UtilizationAnalyticsInterface[]): TimeRatioReportingTableInterface[] => {
    return data.map(
      ({ clinician, organization, direct_time_percentage, indirect_time_percentage, evaluation_time_percentage }) => {
        return {
          clinician: clinician,
          organization: organization.name as string,
          direct_time_percentage: Number(direct_time_percentage),
          indirect_time_percentage: Number(indirect_time_percentage),
          evaluation_time_percentage: Number(evaluation_time_percentage),
        };
      },
    );
  };

  const generateServiceProviderTimeRatioData = (
    data: UtilizationAnalyticsInterface[],
  ): TimeRatioReportingSPTableInterface[] => {
    return data.map(
      ({
        organization,
        contract_id,
        contract_type,
        contracted_weeks_elapsed,
        direct_time,
        direct_time_percentage,
        indirect_time,
        indirect_time_percentage,
        evaluation_time,
        evaluation_time_percentage,
      }) => {
        return {
          organization: organization.name as string,
          contract_id,
          contract_type,
          contracted_weeks_elapsed,
          direct_time: Number(direct_time),
          direct_time_percentage: Number(direct_time_percentage),
          indirect_time: Number(indirect_time),
          indirect_time_percentage: Number(indirect_time_percentage),
          evaluation_time: Number(evaluation_time),
          evaluation_time_percentage: Number(evaluation_time_percentage),
        };
      },
    );
  };

  const updateSOWFilterDate = async (date: Date) => {
    sOWFilterDate.value = useDateTime().formatDateForApi(date);
    await updateSOWData();
  };

  const updateServiceProviderSOWFilterDate = async (date: Date) => {
    serviceProviderSOWFilterDate.value = useDateTime().formatDateForApi(date);
    await updateServiceProviderSOWData();
  };

  const updateTimeRatioFilterDate = async (date: Date) => {
    timeRatioFilterDate.value = useDateTime().formatDateForApi(date);
    await updateTimeRatioData();
  };

  const updateServiceProviderTimeRatioFilterDate = async (date: Date) => {
    serviceProviderTimeRatioFilterDate.value = useDateTime().formatDateForApi(date);
    await updateServiceProviderTimeRatioData();
  };

  const updateDataBasedOnCurrentRoute = async () => {
    if ($routing.route.path === '/dashboard/overview') {
      await updateSOWData();
      await updateTimeRatioData();
    } else if ($routing.route.path === '/dashboard/time-usage') {
      await updateServiceProviderSOWData();
      await updateServiceProviderTimeRatioData();
    }
  };

  // Watches for changes to the CSP or Org filter on the dashboard page and filters down the utilization
  // widgets according to the selected/associated orgs
  watch(
    [
      () => customerSuccessPartnerStore.selectedDashboardCustomerSuccessPartners,
      () => organizationStore.organizationsIdsSelected,
    ],
    async () => {
      const selectedOrgIds = new Set(organizationStore.organizationsIdsSelected.map((org) => org.toString()));
      const selectCSPOrgIds = new Set(
        customerSuccessPartnerStore.selectedDashboardCustomerSuccessPartners.flatMap((csp) =>
          csp.organizations.map((org) => org.toString()),
        ),
      );

      filteredOrganizations.value = Array.from(selectedOrgIds).concat(Array.from(selectCSPOrgIds));
      await updateDataBasedOnCurrentRoute();
    },
  );

  return {
    loading,
    loadingServiceProviderData,
    sOWTableData,
    serviceProviderSOWTableData,
    timeRatioTableData,
    serviceProviderTimeRatioTableData,
    getServiceProviderTimeUsageWidgetData,
    getUtilizationDashboardWidgetData,
    updateSOWFilterDate,
    updateServiceProviderSOWFilterDate,
    updateTimeRatioFilterDate,
    updateServiceProviderTimeRatioFilterDate,
  };
});
