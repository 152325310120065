export enum PERMISSIONS {
  AUTH_ACTIVATE_ACCOUNT = 'auth.activate-account',
  AUTH_LOGIN = 'auth.login',
  AUTH_LOGOUT = 'auth.logout',
  AUTH_PASSWORD_FORGOT_PASSWORD = 'auth.password.forgot-password',
  AUTH_PASSWORD_RESET_SELF = 'auth.password.reset-self',
  AUTH_PASSWORD_RESET_USER = 'auth.password.reset-user',
  AUTH_REFRESH = 'auth.refresh',
  AUTH_VERIFY_TOKEN_EXPIRED = 'auth.verify.token-expired',
  BILLING_ADMIN_ATTACH_ORGANIZATIONS = 'billing-admin.attach-organizations',
  BILLING_ADMIN_DETACH_ORGANIZATIONS = 'billing-admin.detach-organizations',
  BILLING_ADMIN_ORGANIZATIONS_AVAILABLE_TO_ADD = 'billing-admin.organizations.available-to-add',
  CLINICIAN_ANALYTICS = 'clinician-analytics.all',
  CLINICIAN_ATTACH_ORGANIZATIONS = 'clinician.attach-organizations',
  CLINICIAN_ATTACH_STUDENTS = 'clinician.attach-students',
  CLINICIAN_DETACH_ORGANIZATIONS = 'clinician.detach-organizations',
  CLINICIAN_DETACH_STUDENTS = 'clinician.detach-students',
  CLINICIAN_DISCIPLINES_INDEX = 'clinician.disciplines.index',
  CLINICIAN_ORGANIZATIONS_AVAILABLE_TO_ADD = 'clinician.organizations.available-to-add',
  CLINICIAN_STUDENTS_AVAILABLE_TO_ADD = 'clinician.students.available-to-add',
  CLINICIAN_STUDENTS_INDEX = 'clinician.students.index',
  CLINICIAN_SYNC_DISCIPLINES = 'clinician.sync-disciplines',
  CONNECTIONS_INDEX = 'connections.index',
  CONTRACT_TYPES_INDEX = 'contract-types.index',
  CONTRACTCASELOAD_DELETE = 'contractCaseload.delete',
  CONTRACTCASELOAD_SHOW = 'contractCaseload.show',
  CONTRACTCASELOAD_STORE = 'contractCaseload.store',
  CONTRACTCASELOAD_UPDATE = 'contractCaseload.update',
  CONTRACTCLINICIAN_DELETE = 'contractClinician.delete',
  CONTRACTCLINICIAN_SHOW = 'contractClinician.show',
  CONTRACTCLINICIAN_STORE = 'contractClinician.store',
  CONTRACTCLINICIAN_UPDATE = 'contractClinician.update',
  CONTRACTDISCIPLINE_DELETE = 'contractDiscipline.delete',
  CONTRACTDISCIPLINE_SHOW = 'contractDiscipline.show',
  CONTRACTDISCIPLINE_STORE = 'contractDiscipline.store',
  CONTRACTDISCIPLINE_UPDATE = 'contractDiscipline.update',
  CONTRACTEVALUATION_DELETE = 'contractEvaluation.delete',
  CONTRACTEVALUATION_SHOW = 'contractEvaluation.show',
  CONTRACTEVALUATION_STORE = 'contractEvaluation.store',
  CONTRACTEVALUATION_UPDATE = 'contractEvaluation.update',
  CONTRACTFLATFEE_DELETE = 'contractFlatFee.delete',
  CONTRACTFLATFEE_STORE = 'contractFlatFee.store',
  CONTRACTFLATFEE_UPDATE = 'contractFlatFee.update',
  CONTRACTGROUP_DELETE = 'contractGroup.delete',
  CONTRACTGROUP_SHOW = 'contractGroup.show',
  CONTRACTGROUP_STORE = 'contractGroup.store',
  CONTRACTGROUP_UPDATE = 'contractGroup.update',
  CONTRACTHOURLY_DELETE = 'contractHourly.delete',
  CONTRACTHOURLY_STORE = 'contractHourly.store',
  CONTRACTHOURLY_UPDATE = 'contractHourly.update',
  CONTRACTPERSTUDENT_DELETE = 'contractPerStudent.delete',
  CONTRACTPERSTUDENT_STORE = 'contractPerStudent.store',
  CONTRACTPERSTUDENT_UPDATE = 'contractPerStudent.update',
  CONTRACTS_CONTRACT_BY_ORGANIZATION = 'contracts.contract-by-organization',
  CONTRACTS_DELETE = 'contracts.delete',
  CONTRACTS_INDEX = 'contracts.index',
  CONTRACTS_SHOW = 'contracts.show',
  CONTRACTS_STORE = 'contracts.store',
  CONTRACTS_UPDATE = 'contracts.update',
  CONTRACTS_SHOW_SCHEDULE_SCRUB_DATE = 'contracts.show-schedule-scrub-date',
  CONTRACTS_POSTPONE_SCHEDULE_SCRUB_DATE = 'contracts.postpone-schedule-scrub-date',
  COUNTRIES_INDEX = 'countries.index',
  DASHBOARD_ACTIVITY_INDEX = 'dashboard-activity.index',
  DASHBOARD_ACTIVITY_INDEX_ALL = 'dashboard-activity.index.all',
  DASHBOARD_ACTIVITY_STUDENTS = 'dashboard-activity.students',
  DASHBOARD_ACTIVITY_DATE_CLICK = 'dashboard.activity.date.click',
  DASHBOARD_ACTIVITY_DATE_SEARCH = 'dashboard.activity.search',
  DASHBOARD_ACTIVITY_FILTER_EVENT_TYPE_VIEW = 'dashboard-activity.filter-event-type.view',
  DASHBOARD_ACTIVITY_DATE_VIEW = 'dashboard.activity.date.view',
  DASHBOARD_OVERVIEW_ATTENDACE_WIDGET_DATE_VIEW = 'dashboard.overview.attendance-widget.date.view',
  DASHBOARD_OVERVIEW_ATTENDACE_WIDGET_DETAIL_VIEW = 'dashboard.overview.attendance-widget.detail.view',
  DASHBOARD_OVERVIEW_ATTENDACE_WIDGET_PERCENTAGE_VIEW = 'dashboard.overview.attendance-widget.percentage.view',
  DASHBOARD_OVERVIEW_CALENDAR_VIEW_SCHEDULE_VIEW = 'dashboard.overview.calendar.view-schedule.view',
  DASHBOARD_OVERVIEW_COMPLIANCE_WIDGET_DATE_VIEW = 'dashboard.overview.compliance-widget.date.view',
  DASHBOARD_OVERVIEW_COMPLIANCE_WIDGET_PAGINATION_VIEW = 'dashboard.overview.compliance-widget.pagination.view',
  DASHBOARD_OVERVIEW_MEET_WITH_SEARCH_STUDENTS_ADD_EVENT = 'dashboard.overview.meet-with.search-students.add-event',
  DASHBOARD_OVERVIEW_MEET_WITH_SEARCH_STUDENTS_VIEW = 'dashboard.overview.meet-with.search-students.view',
  DISCIPLINES_INDEX = 'disciplines.index',
  DOCUMENTS_DESTROY = 'documents.destroy',
  DOCUMENTS_DOWNLOAD = 'documents.download',
  FACILITATOR_ATTACH_ORGANIZATIONS = 'facilitator.attach-organizations',
  FACILITATOR_DETACH_ORGANIZATIONS = 'facilitator.detach-organizations',
  FACILITATOR_ORGANIZATIONS_AVAILABLE_TO_ADD = 'facilitator.organizations.available-to-add',
  FACILITATOR_ORGANIZATIONS_INDEX = 'facilitator.organizations.index',
  FORGOT_PASSWORD_CANCEL_VIEW = 'forgot-password.cancel.view',
  FORGOT_PASSWORD_CREDENTIALS_VIEW = 'forgot-password.credentials.view',
  IMPERSONATE = 'impersonate',
  IMPERSONATE_LEAVE = 'impersonate.leave',
  LOCATIONS_SHOW = 'locations.show',
  LOCATIONS_STORE = 'locations.store',
  LOCATIONS_UPDATE = 'locations.update',
  MESSAGE_OPEN_MESSAGES_NEW_MESSAGE = 'message.open-messages.new-message',
  MESSAGE_OPEN_MESSAGES_TO_SELECT_RECIPIENT = 'message.open-messages.to-select-recipient',
  MESSAGE_OPEN_MESSAGES_VIEW = 'message.open-messages.view',
  MESSAGE_SEND_MESSAGE = 'message.send-message',
  ORGANIZATION_TYPES_INDEX = 'organization-types.index',
  ORGANIZATION_BILLING_ADMIN_INDEX = 'organization.billing-admin.index',
  ORGANIZATION_DETACH_BILLING_ADMINS = 'organization.detach-billing-admins',
  ORGANIZATION_ATTACH_BILLING_ADMINS = 'organization.attach-billing-admins',
  ORGANIZATION_BILLING_ADMIN_AVAILABLE_TO_ADD = 'organization.billing-admin.available-to-add',
  ORGANIZATION_SYNC_BILLING_ADMINS = 'organization.sync-billing-admins',
  ORGANIZATION_ARCHIVE = 'organization.archive',
  ORGANIZATION_SHOW_ARCHIVE = 'organization..show.archive',
  ORGANIZATION_ATTACH_CLINICIANS = 'organization.attach-clinicians',
  ORGANIZATION_ATTACH_FACILITATORS = 'organization.attach-facilitators',
  ORGANIZATION_ATTACH_PARENTS = 'organization.attach-parents',
  ORGANIZATION_ATTACH_SCHOOL_ADMINS = 'organization.attach-school-admins',
  ORGANIZATION_ATTACH_STUDENTS = 'organization.attach-students',
  ORGANIZATION_CLINICIANS_INDEX = 'organization.clinicians.index',
  ORGANIZATION_CLINICIANS_PARENTS = 'organization.clinicians.parents',
  ORGANIZATION_CLINICIANS_PARENTS_TO_SELECT = 'organization.clinicians.parents.to-select',
  ORGANIZATION_CLINICIANS_INDEX_SELF = 'organization.clinicians.index.self',
  ORGANIZATIONS_CLINICIAN_SHOW_ARCHIVE = 'organization.clinicians.show.archive',
  ORGANIZATION_DETACH_CLINICIANS = 'organization.detach-clinicians',
  ORGANIZATION_DETACH_FACILITATORS = 'organization.detach-facilitators',
  ORGANIZATION_DETACH_PARENTS = 'organization.detach-parents',
  ORGANIZATION_DETACH_SCHOOL_ADMINS = 'organization.detach-school-admins',
  ORGANIZATION_DETACH_STUDENTS = 'organization.detach-students',
  ORGANIZATION_DISCIPLINES = 'organization.disciplines',
  ORGANIZATION_DISCIPLINES_INDEX = 'organization.disciplines.index',
  ORGANIZATION_FACILITATORS_INDEX = 'organization.facilitators.index',
  ORGANIZATION_FACILITATORS_SHOW_ARCHIVED = 'organization.facilitators.show.archived',
  ORGANIZATION_IMPORT_STUDENTS = 'organization.import-students',
  ORGANIZATION_INTEGRATIONS = 'organization.integrations',
  ORGANIZATION_LOCATIONS_INDEX = 'organization.locations.index',
  ORGANIZATION_LOGS = 'organization.logs',
  ORGANIZATION_PARENTS_INDEX = 'organization.parents.index',
  ORGANIZATIONS_PARENTS_SHOW_ARCHIVE = 'organization.parents.show.archive',
  ORGANIZATION_SCHOOL_ADMIN_INDEX = 'organization.school-admin.index',
  ORGANIZATION_SCHOOL_SHOW_ARCHIVED = 'organization.school.show.archived',
  ORGANIZATION_SERVICES = 'organization.services',
  ORGANIZATION_SERVICES_INDEX = 'organization.services.index',
  ORGANIZATION_SHOW = 'organization.show',
  ORGANIZATION_DOCUMENTS_DESTROY = 'organization.documents.destroy',
  ORGANIZATION_DOCUMENTS_DOWNLOAD = 'organization.documents.download',
  ORGANIZATION_DOCUMENTS_INDEX = 'organization.documents.index',
  ORGANIZATION_DOCUMENTS_SHOW = 'organization.documents.show',
  ORGANIZATION_DOCUMENTS_STORE = 'organization.documents.store',
  ORGANIZATION_DOCUMENTS_GENERATE_DATA_EXPORT = 'organization.documents.generate-data-export',
  ORGANIZATION_STUDENT_SHOW_ARCHIVE = 'organization.students.show.archive',
  ORGANIZATION_STUDENT_PARENTS = 'organization.student.parents',
  ORGANIZATION_STUDENTS_INDEX = 'organization.students.index',
  ORGANIZATION_SUB_ORGANIZATIONS = 'organization.sub-organizations',
  ORGANIZATION_SYNC_DISCIPLINES = 'organization.sync-disciplines',
  ORGANIZATION_SYNC_SERVICES = 'organization.sync-services',
  ORGANIZATION_TRANSFER_STUDENTS = 'organization.transfer-students',
  ORGANIZATION_UNARCHIVE = 'organization.unarchive',
  ORGANIZATION_UPDATE = 'organization.update',
  ORGANIZATIONS_INDEX = 'organizations.index',
  ORGANIZATIONS_INDEX_SEARCH = 'organizations.index.search',
  ORGANIZATIONS_CONNECTIONS = 'organizations.connections',
  ORGANIZATIONS_PARENTS = 'organizations.parents',
  ORGANIZATIONS_STORE = 'organizations.store',
  ORGANIZATIONS_TOTALS = 'organizations.totals',
  ORGANIZATIONS_VIEW_ACTIVES = 'organizations.view.actives',
  ORGANIZATIONS_VIEW_TOTALS = 'organizations.view.totals',
  ORGANIZATION_BILLING_DOCUMENTS_INDEX = 'organization.billing-documents.index',
  ORGANIZATION_BILLING_DOCUMENTS_DESTROY = 'organization.billing-documents.destroy',
  ORGANIZATION_BILLING_DOCUMENTS_DOWNLOAD = 'organization.billing-documents.download',
  ORGANIZATION_BILLING_DOCUMENTS_STORE = 'organization.billing-documents.store',
  ORGANIZATION_CREATE_ROOT = 'organization.create.root',
  PARENT_ATTACH_STUDENTS = 'parent.attach-students',
  PARENT_DETACH_STUDENTS = 'parent.detach-students',
  PARENT_STUDENTS_AVAILABLE_TO_ADD = 'parent.students.available-to-add',
  PARENT_STUDENTS_INDEX = 'parent.students.index',
  PAYMENT_INDEX = 'payment.index',
  PAYMENT_DOWNLOAD = 'payment.download',
  PAYMENT_ME = 'payment.me',
  PEOPLE_TYPES_INDEX = 'people-types.index',
  PEOPLE_ARCHIVE = 'people.archive',
  PEOPLE_ARCHIVE_INFO = 'people.archive-info',
  PEOPLE_DESTROY = 'people.destroy',
  PEOPLE_INDEX = 'people.index',
  PEOPLE_INDEX_CLINICIANS = 'people.index.clinicians',
  PEOPLE_INDEX_CONNECTED = 'people.index.connected',
  PEOPLE_INDEX_EXTERNAL_ASSOCIATIONS = 'people.index.external-associations',
  PEOPLE_UPDATE_EXTERNAL_ASSOCIATIONS = 'people.update.external-associations',
  PEOPLE_UPDATE_EXTERNAL_ASSOCIATIONS_SUPER_ADMIN = 'people.update.external-associations.super-admin',
  PEOPLE_UPDATE_EXTERNAL_ASSOCIATIONS_SUPPORT_ADMIN = 'people.update.external-associations.support-admin',
  PEOPLE_UPDATE_EXTERNAL_ASSOCIATIONS_SCHOOL_ADMIN = 'people.update.external-associations.school-admin',
  PEOPLE_INDEX_STUDENT = 'people.index.student',
  PEOPLE_INDEX_STUDENT_EXTERNAL_ASSOCIATIONS = 'people.index.student.external-associations',
  PEOPLE_LOCATION_UPDATE = 'people.location.update',
  PEOPLE_LOCATION_UPDATE_SUPER_ADMIN = 'people.location.update.super-admin',
  PEOPLE_LOCATION_UPDATE_SCHOOL_ADMIN = 'people.location.update.school-admin',
  PEOPLE_LOCATION_UPDATE_SUPPORT_ADMIN = 'people.location.update.support-admin',
  PEOPLE_PERMISSIONS_ASSIGN = 'people.permissions.assign',
  PEOPLE_PERMISSIONS_INDEX = 'people.permissions.index',
  PEOPLE_PERMISSIONS_REVOKE = 'people.permissions.revoke',
  PEOPLE_PREFERENCES_INDEX = 'people.preferences.index',
  PEOPLE_ROLES_ASSIGN = 'people.roles.assign',
  PEOPLE_ROLES_INDEX = 'people.roles.index',
  PEOPLE_ROLES_REVOKE = 'people.roles.revoke',
  PEOPLE_SEARCH = 'people.search',
  PEOPLE_SEND_ACTIVATE_LINK = 'people.send-activate-link',
  PEOPLE_SHOW = 'people.show',
  PEOPLE_STORE = 'people.store',
  PEOPLE_TOTALS = 'people.totals',
  QUICKBOOKS = 'quickbooks.index',
  VIEW_PEOPLE_TOTALS = 'view.people.totals',
  VIEW_PEOPLE_ACTIVE = 'view.people.active',
  PEOPLE_UNARCHIVE = 'people.unarchive',
  PEOPLE_UPDATE = 'people.update',
  PEOPLE_UPDATE_STUDENT_INFORMATION = 'people.update.student.information',
  PEOPLE_UPDATE_SUPER_ADMIN = 'people.update.super-admins',
  PEOPLE_UPDATE_SUPPORT_ADMIN = 'people.update.support-admins',
  PEOPLE_UPDATE_SCHOOL_ADMIN = 'people.update.school-admins',
  PERSON_DOCUMENTS_INDEX = 'person.documents.index',
  PERSON_DOCUMENTS_INDEX_CLINICIANS = 'person.documents.index.clinicians',
  PERSON_DOCUMENTS_INDEX_FACILITATORS = 'person.documents.index.facilitators',
  PERSON_DOCUMENTS_INDEX_STUDENTS = 'person.documents.index.students',
  PERSON_DOCUMENTS_STORE = 'person.documents.store',
  PERSON_DOCUMENTS_STORE_CLINICIANS = 'person.documents.store.clinicians',
  PERSON_DOCUMENTS_STORE_FACILITATORS = 'person.documents.store.facilitators',
  PERSON_DOCUMENTS_STORE_STUDENTS = 'person.documents.store.students',
  PERSON_LOGS_INDEX = 'person.logs.index',
  PERSON_LOGS_INDEX_SEARCH = 'person.logs.index.search',
  PERSON_LOGS_INDEX_FILTER_DATE = 'person.logs.index.filter.date',
  PERSON_LOGS_INDEX_CLINICIANS = 'person.logs.index.clinicians',
  PERSON_LOGS_INDEX_FACILITATOR = 'person.logs.index.facilitator',
  PERSON_LOGS_INDEX_STUDENTS = 'person.logs.index.students',
  PERSON_TREATMENT_PLANS_INDEX = 'person.treatment-plans.index',
  PERSON_TREATMENT_PLANS_INDEX_STUDENTS = 'person.treatment-plans.index.students',
  PERSON_TREATMENT_PLANS_STORE = 'person.treatment-plans.store',
  PERSON_TREATMENT_PLANS_UPDATE_STUDENTS = 'person.treatment-plans.update.students',
  REPORTS_VIEW_INDEX = 'reports.view.index',
  REPORTS_EVENT_SHOW_ALL_COLUMNS_VIEW = 'reports.event.show-all-columns.view',
  REPORTS_EVENTS_DOWNLOAD = 'reports.events.download',
  REPORTS_EVENTS_DOWNLOAD_CSV = 'reports.events.download.csv',
  REPORTS_EVENTS_DOWNLOAD_PDF = 'reports.events.download.pdf',
  REPORTS_EVENTS_DOWNLOAD_XSLX = 'reports.events.download.xslx',
  REPORTS_EVENTS_INDEX = 'reports.events.index',
  REPORTS_EVENTS_PAGINATION = 'reports.events.pagination',
  REPORTS_FILTER_ATTENDEES = 'reports.filter.attendees',
  REPORTS_FILTER_ORGANIZATIONS = 'reports.filter.organizations',
  REPORTS_FILTER_SERVICE_PROVIDERS = 'reports.filter.service-providers',
  REPORTS_FILTER_VIEW = 'reports.filter.view',
  REPORTS_PAYMENT_INDEX = 'payment.index',
  REPORTS_PAYMENT_ME = 'payment.me',
  REPORTS_PAYMENT_DOWNLOAD = 'payment.download',
  REPORTS_PROGRESS_DOWNLOAD = 'reports.progress.download',
  REPORTS_PROGRESS_INDEX = 'reports.progress.index',
  REPORTS_REVIEW_INDEX = 'reports.review.index',
  REPORTS_REVIEW_CLINICIAN_MINUTES = 'reports.review.clinician-minutes',
  REPORTS_REVIEW_CLINICIANS = 'reports.review.clinicians',
  REPORTS_REVIEW_DATE_VIEW = 'reports.review.date.view',
  REPORTS_REVIEW_DISCIPLINE_DROPDOWN_VIEW = 'reports.review.discipline-dropdown.view',
  REPORTS_REVIEW_FILTER_VIEW = 'reports.review.filter.view',
  REPORTS_REVIEW_STUDENTS = 'reports.review.students',
  REPORTS_REVIEW_THERAPY_RESULT = 'reports.review.therapy-result',
  REPORTS_REVIEW_TRACK = 'reports.review.track',
  REPORTS_RSM_INDEX = 'reports.rsm.index',
  REPORTS_RSM_DOWNLOAD_CSV = 'reports.rsm.download.csv',
  REPORTS_RSM_DOWNLOAD_XSLX = 'reports.rsm.download.xslx',
  REPORTS_SELECT_DATES_VIEW = 'reports.select-dates.view',
  REPORTS_STUDENT_TOTAL_INDEX = 'reports.student-total.index',
  ROLE_ASSIGN_ROLE_TO_USERS = 'role.assign.role.to.users',
  ROLE_AVAILABLE_ROLES = 'role.available.roles',
  ROLE_GIVE_PERMISSIONS_TO_ROLE = 'role.give.permissions.to.role',
  ROLE_REVOKE_PERMISSIONS_TO_ROLE = 'role.revoke.permissions.to.role',
  ROLE_REVOKE_ROLE_TO_USERS = 'role.revoke.role.to.users',
  ROLE_ROLE = 'role.role',
  ROLE_STORE = 'role.store',
  ROLE_UPDATE = 'role.update',
  SALESREPS_DELETE = 'salesreps.delete',
  SALESREPS_INDEX = 'salesreps.index',
  SALESREPS_STORE = 'salesreps.store',
  SALESREPS_UPDATE = 'salesreps.update',
  SCHOOL_ADMIN_ATTACH_ORGANIZATIONS = 'school-admin.attach-organizations',
  SCHOOL_ADMIN_DETACH_ORGANIZATIONS = 'school-admin.detach-organizations',
  SCHOOL_ADMIN_ORGANIZATIONS_AVAILABLE_TO_ADD = 'school-admin.organizations.available-to-add',
  SCHOOL_ADMIN_ORGANIZATIONS_INDEX = 'school-admin.organizations.index',
  SERVICES_INDEX = 'services.index',
  STUDENT_ATTACH_CLINICIANS = 'student.attach-clinicians',
  STUDENT_ATTACH_FACILITATORS = 'student.attach-facilitators',
  STUDENT_ATTACH_ORGANIZATIONS = 'student.attach-organizations',
  STUDENT_CLINICIANS_AVAILABLE_TO_ADD = 'student.clinicians.available-to-add',
  STUDENT_CLINICIANS_AVAILABLE_TO_ASSIGN = 'student.clinicians.available-to-assign',
  STUDENT_CLINICIANS_INDEX = 'student.clinicians.index',
  STUDENT_DETACH_CLINICIANS = 'student.detach-clinicians',
  STUDENT_DETACH_FACILITATORS = 'student.detach-facilitators',
  STUDENT_DETACH_ORGANIZATIONS = 'student.detach-organizations',
  STUDENT_FACILITATORS_AVAILABLE_TO_ADD = 'student.facilitators.available-to-add',
  STUDENT_FACILITATORS_INDEX = 'student.facilitators.index',
  STUDENT_FACILITATORS_SHOW = 'student.facilitators.show',
  STUDENT_ORGANIZATIONS_AVAILABLE_TO_ADD = 'student.organizations.available-to-add',
  STUDENT_ORGANIZATIONS_INDEX = 'student.organizations.index',
  STUDENT_PARENTS_INDEX = 'student.parents.index',
  STUDENTS_EXPORT_IEP_INFORMATION = 'students.export-iep-information',
  STUDENTS_IMPORT_IEP_INFORMATION = 'students.import-iep-information',
  REPORTS_SHOW_CLEAR_FILTERS_CLINICIAN = 'reports.show.clear.filters.clinician',
  REPORTS_CHANGE_ORGANIZATIONS_CLINICIAN = 'reports.change.organizations.clinician',
  REPORTS_SHOW_CLINICIAN_NAME = 'reports.show.clinician.name',
  REPORTS_DISPLAY_SELECTED_CLINICIAN_TYPE = 'reports.display.selected.clinician.type',
  TIMEZONES_INDEX = 'timezones.index',
  TENANT_ORGANIZATION = 'tenant.organization',
  TENANT_SETTINGS_UPDATE = 'tenant-settings.update',
  THERAPY_CASELOAD_MANAGEMENT_TYPES_INDEX = 'therapy-caseload-management-types.index',
  THERAPY_EVALUATION_TYPES_INDEX = 'therapy-evaluation-types.index',
  THERAPY_EVENT_DESTROY = 'therapy-event.destroy',
  THERAPY_EVENT_INDEX = 'therapy-event.index',
  THERAPY_EVENT_INDEX_ALL = 'therapy-event.index.all',
  THERAPY_EVENT_INDEX_FILTERS = 'therapy-event.index.filters',
  THERAPY_EVENT_SHOW = 'therapy-event.show',
  THERAPY_EVENT_STORE = 'therapy-event.store',
  THERAPY_EVENT_STORE_EVALUATION_SELECT_ASSESSMENT = 'therapy-event.store.evaluation.select-assessment',
  THERAPY_EVENT_UNLOCK = 'therapy-event.unlock',
  THERAPY_EVENT_UPDATE = 'therapy-event.update',
  THERAPY_RESULT_TYPES_INDEX = 'therapy-result-types.index',
  THERAPY_RESULTS_BULK_UPDATE = 'therapy-results.bulk-update',
  THERAPY_RESULTS_DAY_UPDATE = 'therapy-results.day-update',
  THERAPY_RESULTS_INDEX = 'therapy-results.index',
  THERAPY_RESULTS_SHOW = 'therapy-results.show',
  THERAPY_RESULTS_STORE = 'therapy-results.store',
  THERAPY_SESSION_TYPES_INDEX = 'therapy-session-types.index',
  TREATMENT_GOAL_TYPES_INDEX = 'treatment-goal-types.index',
  TREATMENT_SERVICES_GOALS_INDEX = 'treatment-services.goals.index',
  TREATMENT_SERVICES_GOALS_STORE = 'treatment-services.goals.store',
  TREATMENT_SERVICES_GOALS_UPDATE = 'treatment-services.goals.update',
  TREATMENT_SERVICES_GOALS_DELETE = 'treatment-goals.destroy',
  TREATMENT_PLANS_SERVICES_INDEX = 'treatment-plans.services.index',
  TREATMENT_PLANS_SERVICES_STORE = 'treatment-plans.services.store',
  TREATMENT_PLANS_SERVICES_UPDATE = 'treatment-plan-service.update',
  TREATMENT_PLANS_SERVICE_EXPIRE = 'treatment-plan-service.expire',
  TREATMENT_PLANS_NOTES_INDEX = 'treatment-plans.notes.index',
  TREATMENT_PLANS_NOTES_STORE = 'treatment-plans.notes.store',
  USERS_ORGANIZATION_SHOW = 'users.organization.show',
  USERS_ORGANIZATIONS_SHOW = 'users.organizations.show',
  USERS_PERMISSIONS = 'users.permissions',
  USERS_ROLES_PERMISSIONS = 'users.roles.permissions',
  WIDGET_ATTENDANCE_ALL = 'widget.attendance.all',
  WIDGET_ATTENDANCE_LOW_ATTENDANCE = 'widget.attendance.low-attendance',
  WIDGET_ATTENDANCE_RESULTS = 'widget.attendance.results',
  WIDGET_COMPLIANCE = 'widget.compliance',
  WIDGET_COMPLIANCE_ALL = 'widget.compliance.all',
  WIDGET_EVALUATION = 'widget.evaluation',
  WIDGET_EVALUATION_ALL = 'widget.evaluation.all',
  WIDGET_EVALUATION_ALL_BY_ORGANIZATIONS = 'widget.attendance.all.by-organizations',
  MENU_DASHBOARD_VIEW = 'menu.dashboard.view',
  MENU_BILLING_VIEW = 'menu.billing.view',
  MENU_CALENDAR_VIEW = 'menu.calendar.view',
  MENU_USERS_VIEW = 'menu.users.view',
  MENU_ORGANIZATIONS_VIEW = 'menu.organizations.view',
  MENU_REPORTS_VIEW = 'menu.reports.view',
  MENU_REFERRALS_VIEW = 'menu.referrals.view',
  MENU_SETTINGS_VIEW = 'menu.settings.view',
  BILLING_INDEX = 'billing.index',
  NOTIFICATIONS_EMAIL_FORWARD = 'notifications.email-forward',
  NOTIFICATIONS_EVENT_REMINDER = 'notifications.event-reminder',
  VIEW_ADMINISTRATION = 'view.administration',
  REFERRAL_CASELOAD = 'referral.caseload',
  REFERRAL_OPTIONS = 'referral.options',
  REFERRAL_ASSIGN = 'referral.assign',
  REFERRAL_PROCESS = 'referral.process',
}
