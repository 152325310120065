import { defineStore } from 'pinia';
import { onMounted, ref } from 'vue';
import { CustomerSuccessPartnerService } from '@/services';
import { CustomerSuccessPartnerInterface } from '@/types';

export const useCustomerSuccessPartnerStore = defineStore('CustomerSuccessPartnerStore', () => {
  const loading = ref<boolean>(false);
  const customerSuccessPartners = ref<CustomerSuccessPartnerInterface[]>([]);
  const selectedDashboardCustomerSuccessPartners = ref<CustomerSuccessPartnerInterface[]>([]);

  const getCustomerSuccessPartners = async () => {
    if (customerSuccessPartners.value.length) return;

    loading.value = true;
    const response = await CustomerSuccessPartnerService.getCustomerSuccessPartners();
    customerSuccessPartners.value = response.data.data;
    loading.value = false;
  };

  onMounted(async () => {
    loading.value = true;
    await getCustomerSuccessPartners();
    loading.value = false;
  });

  return {
    customerSuccessPartners,
    loading,
    selectedDashboardCustomerSuccessPartners,
    getCustomerSuccessPartners,
  };
});
