import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class CustomerSuccessPartnerService {
  static customerSuccessPartnerResource = 'customer-success-partners';

  static getCustomerSuccessPartners(): ApiPromise {
    return HTTP.get(`${this.customerSuccessPartnerResource}`);
  }
}
