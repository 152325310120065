import { CONTRACT_BILLING_TYPE } from '@/enum/contract';
import {
  ApiPromise,
  ClinicianContract,
  ContractTypeInterface,
  ContractBillingType,
  ContractDetailInterface,
  EvaluationInterface,
  GroupEventInterface,
} from '@/types';
import { HTTP } from '@/utils';

export default class OrganizationContractsService {
  static resource_org = 'organization';
  static resource_contract_clinician = 'contract-clinician';
  static resource_contracts = 'contracts';
  static resource_contract_discipline = 'contract-discipline';
  static resource_contract_evaluation = 'contract-evaluation';
  static resource_contract_caseload = 'contract-caseload';
  static resource_contract_group = 'contract-group';
  static resource_salesreps = 'salesreps';
  static resource_renew_organizations_contract = 'renew-organizations-contract';

  static getContracts = (id: number): ApiPromise => {
    return HTTP.get(`${this.resource_org}/${id}/${this.resource_contracts}`);
  };

  static getOrganizationContract = (id: number): ApiPromise => {
    return HTTP.get(`${this.resource_contracts}/${id}`);
  };

  static renewOrganizationContracts = (id: number): ApiPromise => {
    return HTTP.post(`${this.resource_renew_organizations_contract}/${id}`);
  };

  static getSalesReps = (): ApiPromise => {
    return HTTP.get(`${this.resource_salesreps}`);
  };

  static getContractTypes = (): ApiPromise => {
    return HTTP.get(`contract-types`);
  };

  static save = (contract: ContractDetailInterface, disciplines: ContractTypeInterface[]) => {
    const contractCopy = { ...contract };
    contractCopy.disciplines = disciplines;
    return HTTP.patch(`${this.resource_contracts}/${contract.id}`, contractCopy);
  };

  static createContract = (contract: ContractDetailInterface, disciplines: ContractTypeInterface[]) => {
    const contractCopy = { ...contract };
    contractCopy.disciplines = disciplines;
    return HTTP.post(`${this.resource_contracts}`, contractCopy);
  };

  static deleteClinician = (id: number, endDates: Record<string, string | null>): Promise<unknown> => {
    const datesNotNull = endDates.schedule_by_date && endDates.document_by_date;
    const queryString = datesNotNull
      ? `?schedule_by_date=${endDates.schedule_by_date}&document_by_date=${endDates.document_by_date}`
      : '';
    return HTTP.delete(`${this.resource_contract_clinician}/${id}${queryString}`);
  };

  static restoreClinician = (id: number): Promise<unknown> => {
    return HTTP.get(`${this.resource_contract_clinician}/${id}/restore`);
  };

  static searchClinician = (search: string): ApiPromise => {
    return HTTP.get(`${this.resource_contract_clinician}?search=${search}`);
  };

  static addClinician = (clinician: ClinicianContract): ApiPromise => {
    return HTTP.post(`${this.resource_contract_clinician}`, clinician);
  };

  static updateServiceProvider = (clinician: ClinicianContract): ApiPromise => {
    return HTTP.patch(`${this.resource_contract_clinician}/${clinician.id}`, clinician);
  };

  static getServiceProviderEventCount = (id: number): ApiPromise => {
    return HTTP.get(`${this.resource_contract_clinician}/${id}/events-count`);
  };

  static addDisciplineToExistingContract = (payload: ContractBillingType): Promise<void> => {
    return HTTP.post(`${this.resource_contract_discipline}`, payload);
  };

  static updateDisciplineOnExistingContract = (
    payload: ContractBillingType,
    contract_type: CONTRACT_BILLING_TYPE,
  ): Promise<void> => {
    return HTTP.patch(`${this.resource_contract_discipline}/${payload.id}`, {
      ...payload,
      billing_type: contract_type,
    });
  };

  static deleteDiscipline = (id: number, endDates: Record<string, string | null>): Promise<void> => {
    const datesNotNull = endDates.schedule_by_date && endDates.document_by_date;
    const queryString = datesNotNull
      ? `?schedule_by_date=${endDates.schedule_by_date}&document_by_date=${endDates.document_by_date}`
      : '';
    return HTTP.delete(`${this.resource_contract_discipline}/${id}${queryString}`);
  };

  static restoreDiscipline = (id: number): Promise<unknown> => {
    return HTTP.get(`${this.resource_contract_discipline}/${id}/restore`);
  };

  static getDisciplineEventCount = (id: number): ApiPromise => {
    return HTTP.get(`${this.resource_contract_discipline}/${id}/events-count`);
  };

  static updateContractEvaluation = (payload: EvaluationInterface): Promise<void> => {
    const postFix = payload.id ? `/${payload.id}` : '';
    if (postFix) return HTTP.patch(`${this.resource_contract_evaluation}${postFix}`, payload);
    return HTTP.post(`${this.resource_contract_evaluation}`, payload);
  };

  static addOrUpdateContractGroup = (payload: GroupEventInterface): Promise<void> => {
    const postFix = payload?.id ? `/${payload?.id}` : '';
    if (postFix) return HTTP.patch(`${this.resource_contract_group}${postFix}`, payload);
    return HTTP.post(`${this.resource_contract_group}`, payload);
  };

  static deleteContractGroup = (payload: number): Promise<void> => {
    return HTTP.delete(`${this.resource_contract_group}/${payload}`);
  };

  static addContractCaseload = (payload: {
    therapy_caseload_management_type_id: number;
    contract_id: number;
  }): Promise<void> => {
    return HTTP.post(`${this.resource_contract_caseload}`, payload);
  };

  static deleteContractCaseload = (payload: number): Promise<void> => {
    return HTTP.delete(`${this.resource_contract_caseload}/${payload}`);
  };

  static getEventsRemovedByDateChange = (payload: Record<string, string | number | null>): ApiPromise => {
    let queryString = `end_at=${payload.end_at}&start_at=${payload.start_at}`;
    if (payload.clinician_id) queryString += `&clinician_id=${payload.clinician_id}`;
    if (payload.discipline_id) queryString += `&discipline_id=${payload.discipline_id}`;

    return HTTP.get(`${this.resource_contracts}/${payload.id}/affected-events?${queryString}`);
  };

  static getScheduleScrub = (organizationId: number): ApiPromise => {
    return HTTP.get(`${this.resource_org}/${organizationId}/show-schedule-scrub`);
  };

  static postponeScheduleScrub = (organizationId: number, scheduleScrub: string): ApiPromise => {
    return HTTP.post(`${this.resource_org}/${organizationId}/postpone-schedule-scrub`, {
      schedule_scrub: scheduleScrub,
    });
  };
}
