import { can, useDateTime } from '@/composables';
import {
  ClinicianContract,
  ContractBillingType,
  ContractBillingTypeHourlyInterface,
  ContractCaseloadInterface,
  ContractClinicianInterface,
  ContractDetailInterface,
  ContractTypeObjectInterface,
  DisciplineInterface,
  DropdownOptionInterface,
  EvaluationInterface,
  GroupEventInterface,
  SalesRepInterface,
} from '@/types';
import { computed, onMounted, ref } from 'vue';
import { CONTRACT_BILLING_TYPE } from '@/enum/contract';
import { CONTRACT_ENUMS, PERMISSIONS } from '@/enum';
import { defineStore } from 'pinia';
import { isNumber } from '@vueuse/core';
import { OrganizationContractsService } from '@/services';
import { TreeNode } from 'primevue/tree';
import { useDisciplineStore, useOrganizationStore } from '@/store';

import CONTRACT_EDIT_TYPES = CONTRACT_ENUMS.CONTRACT_EDIT_TYPES;
import CONTRACT_VERSION_TYPE = CONTRACT_ENUMS.CONTRACT_VERSION_TYPE;

interface ContractPendingRequestInterface {
  request: () => Promise<void>;
  action: ACTION_TYPE;
  subject: SUBJECT_TYPE;
  subjectId?: number;
}

enum ACTION_TYPE {
  ADD = 'add',
  DELETE = 'delete',
  UPDATE = 'update',
  RESTORE = 'restore',
}

enum SUBJECT_TYPE {
  DISCIPLINE = 'discipline',
  CLINICIAN = 'clinician',
  OTHER = 'other',
}

export const useOrganizationContractsStore = defineStore('OrganizationContractsStore', () => {
  const disciplineStore = useDisciplineStore();
  const organizationStore = useOrganizationStore();
  const canEditOrCreateContracts = computed<boolean>(() =>
    can([PERMISSIONS.CONTRACTS_STORE, PERMISSIONS.CONTRACTS_UPDATE]),
  );
  const dateUtil = useDateTime();
  const organizationId = computed(() => organizationStore.organization.id);
  const organizationName = computed(() => organizationStore.organization.name);
  const selectedContractStarted = computed(() => {
    const contractStart = new Date(contract.value.start_at);
    return contractStart instanceof Date && !isNaN(contractStart.valueOf()) && contractStart < new Date();
  });
  const scheduleScrub = ref<string | null>('');
  const minScheduleScrubDate = ref<string>('');
  const maxScheduleScrubDate = ref<string>('');
  const isScrubbed = ref<boolean>(false);

  const loading = ref<boolean>(true);
  const error = ref<string>('');
  const editType = ref<CONTRACT_EDIT_TYPES>(CONTRACT_EDIT_TYPES.NEW);
  const contract = ref<ContractDetailInterface>({} as ContractDetailInterface);
  const contracts = ref<ContractDetailInterface[]>([]);
  const contractsTableData = ref<TreeNode[]>([]);
  const contractTypes = ref<ContractTypeObjectInterface[]>([]);
  const salesReps = ref<SalesRepInterface[]>([]);
  const pendingRequests = ref<ContractPendingRequestInterface[]>([]);
  const originalCaseloads = ref<ContractCaseloadInterface[]>([]);
  const availableDisciplinesData = ref<{ [key in CONTRACT_BILLING_TYPE]: DisciplineInterface[] }>({
    [CONTRACT_BILLING_TYPE.FLAT_FEE]: [],
    [CONTRACT_BILLING_TYPE.HOURLY]: [],
    [CONTRACT_BILLING_TYPE.PER_STUDENT]: [],
  });
  const FILTER = ref<Record<string, string>>({
    ALL: 'All',
    CURRENT: 'Current',
    UPCOMING: 'Upcoming',
    COMPLETED: 'Completed',
  });

  onMounted(async () => {
    contractTypes.value = await getContractTypes();
  });

  const getNewContractTemplate = async (): Promise<ContractDetailInterface> => {
    editType.value = CONTRACT_EDIT_TYPES.NEW;
    const newContractTemplate = {
      id: 0,
      billing_type: CONTRACT_BILLING_TYPE.FLAT_FEE,
      organization_id: organizationId.value,
      contract_type: {
        id: 1,
        type: CONTRACT_VERSION_TYPE.NEW,
      },
      start_at: '',
      end_at: '',
      service_start_at: '',
      service_end_at: '',
      payment_start_at: '',
      payment_end_at: '',
      salesrep: undefined,
      quote_number: '',
      contract_identifier: '',
      prepay: false,
      third_party: false,
      split_caseload: false,
      fee_for_service: false,
      hours_contracted: 0,
      noshow_rate: undefined,
      noshow_is_fixed: undefined,
      clinicians: [],
      caseloads: [],
      groups: createGroupEventArray(),
      evaluation: {
        id: 0,
        contract_id: 0,
        noshow_rate: 0,
        noshow_rate_is_fixed: false,
        rate: 0,
        rate_has_max: false,
        rate_is_fixed: false,
        rate_max_hours: 0,
        window_length: 0,
      },
      disciplines: [],
      parent_id: undefined,
    };
    contract.value = newContractTemplate;
    await getMultiDisciplineData();
    contract.value.disciplines = availableDisciplinesData.value[CONTRACT_BILLING_TYPE.FLAT_FEE];
    return contract.value;
  };

  // creates an iterable async generator to be used to fire pending requests sequentially
  async function* generateAsyncRequests(requests: ContractPendingRequestInterface[]) {
    while (requests.length) {
      const request = requests.shift();
      if (typeof request?.request === 'function') {
        yield await request?.request().catch((e) => {
          //TODO here is where we can pop a toast for errors.
          //eslint-disable-next-line
          console.error('error', e);
        });
      }
    }
  }

  // uses the generator above to run each async request in a for await loop
  async function doPendingRequests() {
    const generator = generateAsyncRequests(pendingRequests.value);
    //eslint-disable-next-line
    for await (let value of generator) {
      //TODO here is where we can pop a toast for successes.
    }
  }

  async function getContracts() {
    loading.value = true;
    const response = await OrganizationContractsService.getContracts(organizationId.value);
    contracts.value = response.data.data as ContractDetailInterface[];
    formatContractsForExpandedRows();
    loading.value = false;
  }

  async function showContract(id: number) {
    loading.value = true;

    await getOrganizationContract(id);
    editType.value = CONTRACT_EDIT_TYPES.EDIT;
    loading.value = false;
  }

  async function renewOrganizationContracts(id: number) {
    loading.value = true;

    await getOrganizationContract(id);
    editType.value = CONTRACT_EDIT_TYPES.RENEW;
    contract.value.start_at = dateUtil.addYearsToDate(contract.value.start_at);
    contract.value.end_at = dateUtil.addYearsToDate(contract.value.end_at);
    contract.value.service_start_at = dateUtil.addYearsToDate(contract.value.service_start_at);
    contract.value.service_end_at = dateUtil.addYearsToDate(contract.value.service_end_at);
    contract.value.payment_start_at = dateUtil.addYearsToDate(contract.value.payment_start_at);
    contract.value.payment_end_at = dateUtil.addYearsToDate(contract.value.payment_end_at);
    contract.value.clinicians?.forEach((clinician) => {
      // to allow clinicians to be added on contract creation (rather than only after contract creation),
      // we set the contract_id to undefined so that the create contract clinician request suceeds with
      // can use the contract_id of the newly created contract on save
      clinician.contract_id = undefined;
      clinician.person_id = clinician.person?.id;
      addServiceProviderToPendingRequests(clinician, false);
    });
    contract.value.id = undefined;
    contract.value.parent_id = id;
    contract.value.contract_type = {
      id: 2,
      type: CONTRACT_VERSION_TYPE.RENEWAL,
    };

    loading.value = false;
  }

  async function expandOrganizationContract(id: number) {
    loading.value = true;
    const parentContract = contracts.value.find((contract) => {
      return contract.id === id;
    });

    getNewContractTemplate();
    contract.value.id = undefined;
    contract.value.parent_id = id;
    contract.value.start_at = parentContract?.start_at as string;
    contract.value.end_at = parentContract?.end_at as string;
    contract.value.service_start_at = parentContract?.service_start_at as string;
    contract.value.service_end_at = parentContract?.service_end_at as string;
    contract.value.payment_start_at = parentContract?.payment_start_at as string;
    contract.value.payment_end_at = parentContract?.payment_end_at as string;
    contract.value.contract_type = {
      id: 3,
      type: CONTRACT_VERSION_TYPE.EXPANSION,
    };

    loading.value = false;
  }

  function filterContracts(label: string) {
    const today = new Date().getTime();

    switch (label) {
      case FILTER.value.CURRENT: {
        const rawContracts = contracts.value.filter(
          (contract) => new Date(contract.start_at).getTime() < today && new Date(contract.end_at).getTime() > today,
        );
        formatContractsForExpandedRows(rawContracts);
        break;
      }
      case FILTER.value.UPCOMING: {
        const rawContracts = contracts.value.filter((contract) => new Date(contract.start_at).getTime() > today);
        formatContractsForExpandedRows(rawContracts);
        break;
      }
      case FILTER.value.COMPLETED: {
        const rawContracts = contracts.value.filter((contract) => new Date(contract.end_at).getTime() < today);
        formatContractsForExpandedRows(rawContracts);
        break;
      }
      case FILTER.value.ALL:
      default:
        formatContractsForExpandedRows();
    }
  }

  async function save() {
    loading.value = true;
    //create patch requests for each service provider in case changes were made to their rates
    updateServiceProviderPendingRequests();

    //prepare contract for save
    contract.value.contract_type_id = contract.value.contract_type.id;
    contract.value.salesrep_id = contract.value.salesrep?.id;
    // the parent_id must be removed from the payload when patching an existing contract
    delete contract.value.parent_id;

    //prepare post/delete/patch requests for each discipline that has been modified in any way
    createDisciplineRequests();
    //prepare evaulations update request in case an evaluation datapoint has been modified
    createEvaluationUpdateRequest();
    //prepare caseloads update request in case a caseload management type was modified
    createCaseloadUpdateRequest();
    //prepare groups requests for each new/modified/removed group
    createGroupUpdateRequest();
    contract.value.caseloads = contract.value.caseloads?.map((caseload) => {
      if (isNumber(caseload)) return caseload;
      return caseload.value;
    });

    const response = await OrganizationContractsService.save(contract.value, formatDisciplinesForApi.value).catch(
      (err) => {
        error.value = err;
        loading.value = false;
        pendingRequests.value = [];
        throw err;
      },
    );
    await doPendingRequests();
    loading.value = false;
    pendingRequests.value = [];
    return response;
  }

  async function createContract() {
    loading.value = true;

    contract.value.contract_type_id = contract.value.contract_type.id;
    contract.value.salesrep_id = contract.value.salesrep?.id;
    contract.value.caseloads = contract.value.caseloads?.map((caseload) => {
      if (isNumber(caseload)) return caseload;
      return caseload.value;
    });
    // if the groups array is empty, remove the groups prop from the payload
    if (contract.value.billing_type !== CONTRACT_BILLING_TYPE.HOURLY) {
      delete contract.value.groups;
    } else {
      const groupSelection = contract.value.groups?.filter((group) => group.active && group.number_of_attendees !== 0);
      contract.value.groups = groupSelection;
      if (!contract.value.groups?.length) delete contract.value.groups;
    }

    const response = await OrganizationContractsService.createContract(
      contract.value,
      formatDisciplinesForApi.value,
    ).catch((err) => {
      error.value = err;
      loading.value = false;
      pendingRequests.value = [];
      throw err;
    });

    contract.value.id = response?.data.data.id;
    await doPendingRequests();
    loading.value = false;
    pendingRequests.value = [];
    return response;
  }

  /*
    SERVICE PROVIDER FUNCTIONS
  */

  // temporarily adds a clinician from a contract, and adds an addServiceProviderToPendingRequests request to
  // the pending requests array to be ran after save has been pushed
  function addServiceProviderToPendingRequests(serviceProvider: ContractClinicianInterface, addToContractData = true) {
    if (addToContractData) contract.value.clinicians?.push(serviceProvider as ContractClinicianInterface);

    const preventRequest = filterPendingRequests([ACTION_TYPE.DELETE], SUBJECT_TYPE.CLINICIAN, serviceProvider.id);

    if (preventRequest) return;

    serviceProvider.toBeAddedToContract = true;
    const addServiceProviderFunc = {
      request: async () => await addServiceProviderRequest(serviceProvider as ClinicianContract),
      action: ACTION_TYPE.ADD,
      subject: SUBJECT_TYPE.CLINICIAN,
      subjectId: serviceProvider.id,
    };
    pendingRequests.value.push(addServiceProviderFunc);
  }

  function updateServiceProviderPendingRequests() {
    const updatedClinicians = contract.value.clinicians?.filter((clinician) => {
      return clinician.updated && !clinician.toBeAddedToContract;
    });
    updatedClinicians?.forEach((clinician) => {
      const updateServiceProviderFunc = {
        request: async () => await updateServiceProviderRequest(clinician),
        action: ACTION_TYPE.UPDATE,
        subject: SUBJECT_TYPE.CLINICIAN,
        subjectId: clinician.id,
      };
      pendingRequests.value.push(updateServiceProviderFunc);
    });
  }

  // adds a new service provider to a contract, if the contract is new, this function fires after the contract
  // creation request and pulls the contract_id from the contract creation success response
  const addServiceProviderRequest = async (serviceProvider: ContractClinicianInterface | ClinicianContract) => {
    if (!serviceProvider.contract_id) serviceProvider.contract_id = contract.value.id;
    await OrganizationContractsService.addClinician(serviceProvider as ClinicianContract);
  };

  const updateServiceProviderRequest = async (serviceProvider: ContractClinicianInterface | ClinicianContract) => {
    await OrganizationContractsService.updateServiceProvider(serviceProvider as ClinicianContract);
  };

  // temporarily deletes a clinician from a contract, and adds a deleteServiceProvider request to
  // the pending requests array to be ran after save has been pushed
  async function deleteServiceProvider(id: number, endDates: Record<string, string | null>, removeFromUI = true) {
    if (removeFromUI) removeServiceProviderFromContractData(id);

    if (Number.isInteger(id)) {
      const preventRequest = filterPendingRequests([ACTION_TYPE.ADD, ACTION_TYPE.RESTORE], SUBJECT_TYPE.CLINICIAN, id);

      if (preventRequest) return;

      const removeServiceProviderFunc = {
        request: async () => (await OrganizationContractsService.deleteClinician(id, endDates)) as Promise<void>,
        action: ACTION_TYPE.DELETE,
        subject: SUBJECT_TYPE.CLINICIAN,
        subjectId: id,
      };
      pendingRequests.value.push(removeServiceProviderFunc);
    }
  }

  async function restoreServiceProvider(id: number) {
    if (Number.isInteger(id)) {
      const preventRequest = filterPendingRequests([ACTION_TYPE.DELETE], SUBJECT_TYPE.CLINICIAN, id);

      if (preventRequest) return;

      const restoreServiceProviderFunc = {
        request: async () => (await OrganizationContractsService.restoreClinician(id)) as Promise<void>,
        action: ACTION_TYPE.RESTORE,
        subject: SUBJECT_TYPE.CLINICIAN,
        subjectId: id,
      };

      pendingRequests.value.push(restoreServiceProviderFunc);
    }
  }

  async function getServiceProviderEventCount(id: number) {
    const response = await OrganizationContractsService.getServiceProviderEventCount(id);
    return response?.data?.data?.events_count || 0;
  }

  /*
    DISCIPLINE FUNCTIONS
  */
  async function deleteDiscipline(id: number, endDates: Record<string, string | null>) {
    const preventRequest = filterPendingRequests([ACTION_TYPE.ADD, ACTION_TYPE.RESTORE], SUBJECT_TYPE.DISCIPLINE, id);

    if (preventRequest) return;

    const deleteDisciplineFunc = {
      request: async () => await OrganizationContractsService.deleteDiscipline(id, endDates),
      action: ACTION_TYPE.DELETE,
      subject: SUBJECT_TYPE.DISCIPLINE,
      subjectId: id,
    };

    pendingRequests.value.push(deleteDisciplineFunc);
  }

  async function addDisciplineToExistingContract(discipline: ContractBillingType) {
    const preventRequest = filterPendingRequests(
      [ACTION_TYPE.DELETE],
      SUBJECT_TYPE.DISCIPLINE,
      discipline.id as number,
    );

    if (preventRequest) return;
    const payload = {
      contract_id: contract.value.id,
      ...discipline,
      billing_type: contract.value.billing_type,
    };
    const addDisciplineFunc = {
      request: async () => await OrganizationContractsService.addDisciplineToExistingContract(payload),
      action: ACTION_TYPE.ADD,
      subject: SUBJECT_TYPE.DISCIPLINE,
      subjectId: discipline.discipline_id as number,
    };

    pendingRequests.value.push(addDisciplineFunc);
  }

  async function updateDisciplineOnExistingContract(discipline: ContractBillingType) {
    const preventRequest = filterPendingRequests(
      [ACTION_TYPE.DELETE],
      SUBJECT_TYPE.DISCIPLINE,
      discipline.id as number,
      true,
    );

    if (preventRequest) return;
    const payload = { ...discipline };
    const updateDisciplineFunc = {
      request: async () =>
        await OrganizationContractsService.updateDisciplineOnExistingContract(payload, contract.value.billing_type),
      action: ACTION_TYPE.UPDATE,
      subject: SUBJECT_TYPE.DISCIPLINE,
      subjectId: discipline.discipline_id as number,
    };

    pendingRequests.value.push(updateDisciplineFunc);
  }

  async function restoreDiscipline(id: number) {
    if (Number.isInteger(id)) {
      const preventRequest = filterPendingRequests([ACTION_TYPE.DELETE], SUBJECT_TYPE.DISCIPLINE, id);

      if (preventRequest) return;

      const restoreDisciplineFunc = {
        request: async () => (await OrganizationContractsService.restoreDiscipline(id)) as Promise<void>,
        action: ACTION_TYPE.RESTORE,
        subject: SUBJECT_TYPE.DISCIPLINE,
        subjectId: id,
      };

      pendingRequests.value.push(restoreDisciplineFunc);
    }
  }

  async function getDisciplineEventCount(
    id: number,
  ): Promise<{ events_count: number; service_provider_count: number }> {
    const response = await OrganizationContractsService.getDisciplineEventCount(id);
    return response?.data?.data || { events_count: 0, service_provider_count: 0 };
  }

  /**
   * Given an array of actions, the subject type, and the subject id, this function will remove any
   * pending reqesuts that match all of the criteria fed into it. This is useful if we have multiple
   * actions happening to the same subject.
   *
   * Ex. clinician is added to a contract, the request is added to the pending requests. Before save
   * the contract creator removes the clinician from the contract. now adding a delete request to the
   * pending requests. So we have two requests that cancel eachother out. This function will aid in
   * removing unnecessary or potentially breaking requests from the pending requests array.
   *
   * @param actions types of actions to be removed from the pendingRequests array
   * @param subject type of subject the function is aiming to remove from the pending requests array
   * @param subjectId id of the subject we are checking the pending requests array for
   * @param dryRun if you do not want the pendingRequests array to be modified, set this to true
   * @returns boolean returns true if the pending requests array length changed during this filter event
   */
  function filterPendingRequests(
    actions: ACTION_TYPE[],
    subject: SUBJECT_TYPE,
    subjectId: number,
    dryRun = false,
  ): boolean {
    let requests = Array.from(pendingRequests.value);

    const startingArrayLength = requests.length;
    requests = requests.filter((request) => {
      return !(actions.includes(request.action) && request.subject === subject && request.subjectId === subjectId);
    });
    const endingArrayLength = requests.length;

    if (!dryRun) pendingRequests.value = requests;

    return startingArrayLength !== endingArrayLength;
  }

  async function createEvaluationUpdateRequest() {
    const { evaluation } = contract.value;

    const updateEvaluationFunc = {
      request: async () =>
        await OrganizationContractsService.updateContractEvaluation(evaluation as EvaluationInterface),
      action: ACTION_TYPE.UPDATE,
      subject: SUBJECT_TYPE.OTHER,
      subjectId: evaluation?.id,
    };

    pendingRequests.value.push(updateEvaluationFunc);
  }

  async function createCaseloadUpdateRequest() {
    const { caseloads } = contract.value;

    originalCaseloads.value.forEach((caseload) => {
      if (
        !(caseloads as DropdownOptionInterface[])?.filter(
          (c) => c.value == caseload.therapy_caseload_management_type_id,
        ).length
      ) {
        const deleteCaseloadFunc = {
          request: async () => await OrganizationContractsService.deleteContractCaseload(caseload.id),
          action: ACTION_TYPE.DELETE,
          subject: SUBJECT_TYPE.OTHER,
          subjectId: caseload?.id,
        };

        pendingRequests.value.push(deleteCaseloadFunc);
      }
    });
    (caseloads as DropdownOptionInterface[])?.forEach((caseload) => {
      if (!originalCaseloads.value.filter((c) => c.therapy_caseload_management_type_id == caseload.value).length) {
        const payload = {
          contract_id: contract.value.id as number,
          therapy_caseload_management_type_id: caseload.value,
        };
        const addCaseloadFunc = {
          request: async () => await OrganizationContractsService.addContractCaseload(payload),
          action: ACTION_TYPE.ADD,
          subject: SUBJECT_TYPE.OTHER,
          subjectId: caseload?.value,
        };

        pendingRequests.value.push(addCaseloadFunc);
      }
    });
  }

  // Checks the groups array for any added, deleted, or modified groups and adds the corresponding
  // request to the pending requests queue.
  async function createGroupUpdateRequest() {
    if (contract.value.billing_type !== CONTRACT_BILLING_TYPE.HOURLY) return;

    const { groups } = contract.value;
    const existingActiveGroups = groups?.filter((group) => group.id && group.active);
    const existingInactiveGroups = groups?.filter((group) => group.id && !group.active);
    const newActiveGroups = groups?.filter((group) => !group.id && group.active && group.number_of_attendees);
    existingInactiveGroups?.forEach((groupToDelete) => {
      const deleteGroupsFunc = {
        request: async () => await OrganizationContractsService.deleteContractGroup(groupToDelete?.id as number),
        action: ACTION_TYPE.DELETE,
        subject: SUBJECT_TYPE.OTHER,
        subjectId: groupToDelete?.id || 0,
      };

      pendingRequests.value.push(deleteGroupsFunc);
    });

    existingActiveGroups?.forEach((groupToUpdate) => {
      const updateGroupsFunc = {
        request: async () =>
          await OrganizationContractsService.addOrUpdateContractGroup(groupToUpdate as GroupEventInterface),
        action: ACTION_TYPE.UPDATE,
        subject: SUBJECT_TYPE.OTHER,
        subjectId: groupToUpdate?.id || 0,
      };

      pendingRequests.value.push(updateGroupsFunc);
    });

    newActiveGroups?.forEach((groupToUpdate) => {
      groupToUpdate.contract_id = contract.value.id;
      const addGroupsFunc = {
        request: async () =>
          await OrganizationContractsService.addOrUpdateContractGroup(groupToUpdate as GroupEventInterface),
        action: ACTION_TYPE.ADD,
        subject: SUBJECT_TYPE.OTHER,
        subjectId: 0,
      };

      pendingRequests.value.push(addGroupsFunc);
    });
  }

  async function getOrganizationContract(id: number) {
    loading.value = true;
    const response = await OrganizationContractsService.getOrganizationContract(id);
    contract.value = response.data.data;

    contract.value.evaluation ||= {
      // Logical OR assignment
      id: 0,
      contract_id: contract.value.id as number,
      noshow_rate: 0,
      noshow_rate_is_fixed: false,
      rate: 0,
      rate_has_max: false,
      rate_is_fixed: false,
      rate_max_hours: 0,
      window_length: 0,
    };
    originalCaseloads.value = response.data.data.caseloads;
    contract.value.caseloads =
      response.data.data.caseloads?.map((caseload: ContractCaseloadInterface) => {
        return {
          name: caseload.caseload_management_type?.name,
          value: caseload.caseload_management_type?.id,
        };
      }) || [];
    contract.value.groups = createGroupEventArray(contract.value.groups);
    setContractDisciplinesActive();
    await getMultiDisciplineData(contract.value.disciplines as DisciplineInterface[]);
    const billingType: CONTRACT_BILLING_TYPE = (contract.value.disciplines[0] as DisciplineInterface).contract_type;
    contract.value.disciplines = availableDisciplinesData.value[billingType];
    if (billingType === CONTRACT_BILLING_TYPE.HOURLY) setHourlyContractValues();
    loading.value = false;
  }

  function setHourlyContractValues() {
    //eslint-disable-next-line
    //@ts-ignore
    const activeDiscipline = contract.value.disciplines.filter((discipline) => discipline.active)[0].contract_type_data;
    contract.value.hours_contracted = activeDiscipline?.hours_contracted || 0;
    contract.value.fee_for_service = activeDiscipline?.fee_for_service || 0;
  }

  async function getSalesReps() {
    const response = await OrganizationContractsService.getSalesReps();
    salesReps.value = response.data.data;
  }

  async function getContractTypes() {
    const response = await OrganizationContractsService.getContractTypes();
    return response.data.data;
  }

  async function checkDateRangeEventRemoval({
    clinician_id,
    discipline_id,
    start_at,
    end_at,
  }: { clinician_id?: number; discipline_id?: number; start_at?: string; end_at?: string } = {}) {
    const payload = {
      clinician_id: clinician_id || null,
      discipline_id: discipline_id || null,
      start_at: start_at || contract.value.start_at,
      end_at: end_at || contract.value.end_at,
      id: contract.value.id as number,
    };

    const response = await OrganizationContractsService.getEventsRemovedByDateChange(payload);
    return response.data.data;
  }

  // the modal needs to have 3 separate discipline schemas available in case a user wants to
  // swap out the billing type at any time. This function creates that data, while also folding in
  // existing disciplines data for already existing contracts
  async function getMultiDisciplineData(disciplines?: DisciplineInterface[]) {
    const orgDisciplines = await disciplineStore.getDisciplines(organizationId.value);
    const contractBillingType = disciplines?.length ? disciplines[0].contract_type : null;
    const contract_type_data: { [key in CONTRACT_BILLING_TYPE]: ContractBillingType } = {
      [CONTRACT_BILLING_TYPE.HOURLY]: {
        rate: 0,
        fee_for_service: false,
        hours_contracted: 0,
      },
      [CONTRACT_BILLING_TYPE.FLAT_FEE]: {
        id: 0,
        annual_rate: 0,
        hours_contracted: 0,
        evaluations: 0,
      },
      [CONTRACT_BILLING_TYPE.PER_STUDENT]: {
        per_student_rate: 0,
        hours_per_week: 0,
        hours_per_year: 0,
        minimum: 0,
        weeks_in_year: 0,
      },
    };
    Object.keys(availableDisciplinesData.value).forEach((value: string) => {
      //eslint-disable-next-line
      //@ts-ignore
      availableDisciplinesData.value[value as CONTRACT_BILLING_TYPE] = orgDisciplines?.map((discipline) => {
        return {
          contract_id: 0,
          contract_type: value,
          contract_type_data: { ...contract_type_data[value as CONTRACT_BILLING_TYPE] },
          discipline,
          discipline_id: discipline.id,
        };
      }) as { [key in CONTRACT_BILLING_TYPE]?: DisciplineInterface[] };

      if (contractBillingType && contractBillingType === value) {
        disciplines?.forEach((discipline) => {
          const existingDisciplineIndex = availableDisciplinesData.value[value as CONTRACT_BILLING_TYPE]?.findIndex(
            (availableDiscipline) => {
              return availableDiscipline.discipline_id === discipline.discipline_id;
            },
          );
          if (existingDisciplineIndex !== -1) {
            (availableDisciplinesData.value[value as CONTRACT_BILLING_TYPE] as DisciplineInterface[])[
              existingDisciplineIndex as number
            ] = discipline;
          }
        });
      }
    });
  }

  function setContractDisciplinesActive() {
    contract.value.disciplines.forEach((_, index, disciplineArray) => {
      (disciplineArray[index] as DisciplineInterface).active = true;
    });
  }

  const formatDisciplinesForApi = computed<ContractBillingType[]>(() => {
    const billingType = contract.value.billing_type;
    const disciplines: DisciplineInterface[] = [
      ...(availableDisciplinesData.value[billingType] as DisciplineInterface[]),
    ].filter((d) => d.active);

    return disciplines.map((discipline) => {
      let { contract_type_data } = discipline;

      if (billingType == CONTRACT_BILLING_TYPE.HOURLY) {
        (contract_type_data as ContractBillingTypeHourlyInterface) = {
          hours_contracted: contract.value.hours_contracted as number,
          fee_for_service: contract.value.fee_for_service as boolean,
          rate: (contract_type_data as ContractBillingTypeHourlyInterface).rate,
        };
      }

      return {
        contract_id: discipline.contract_id ? discipline.contract_id : contract.value.id,
        contractable_id: discipline.contract_type_data.id,
        contractable_type: discipline.contract_type,
        ...contract_type_data,
        discipline_id: discipline.discipline_id,
        id: discipline.id,
      };
    });
  });

  const createDisciplineRequests = () => {
    const disciplines = formatDisciplinesForApi.value;
    disciplines.forEach((discipline) => {
      if (!discipline.id) {
        addDisciplineToExistingContract(discipline);
      } else {
        updateDisciplineOnExistingContract(discipline);
      }
    });
  };

  /*
    Creates the group event objects array, existing group event objects can be passed as an optional
    parameter to modify the default array values.
  */
  const createGroupEventArray = (existingData?: GroupEventInterface[]): GroupEventInterface[] => {
    const newGroupArray = [
      {
        number_of_attendees: 0,
        attendees_rate: 0,
        attendees_rate_is_fixed: 0,
        noshow_rate: 0,
        active: false,
        hidden: true,
      },
      {
        number_of_attendees: 1,
        attendees_rate: 100,
        attendees_rate_is_fixed: 0,
        noshow_rate: 0,
        active: false,
        hidden: true,
      },
      {
        number_of_attendees: 2,
        attendees_rate: 0,
        attendees_rate_is_fixed: 0,
        noshow_rate: 0,
        active: false,
      },
      {
        number_of_attendees: 3,
        attendees_rate: 0,
        attendees_rate_is_fixed: 0,
        noshow_rate: 0,
        active: false,
      },
    ];

    if (!existingData) return newGroupArray;
    if (!existingData.length) {
      newGroupArray[0].active = true;
      return newGroupArray;
    }
    // Here we take any existing groups returned from the contract and merge them with our defaults
    return newGroupArray.map((group) => {
      const existingGroupObject = existingData.find((existingGroup) => {
        return existingGroup.number_of_attendees == group.number_of_attendees;
      });

      if (existingGroupObject) {
        // We want to set the hidden property to true if it is the 1 attendee or unlimited group object.
        // A group object with num_of_attendees === 1 is the default group object for when 2, 3,
        // and unlimited are all disabled.
        const shouldBeHidden =
          existingGroupObject.number_of_attendees === 1 || !existingGroupObject.number_of_attendees;
        if (shouldBeHidden) existingGroupObject.hidden = true;
        existingGroupObject.active = true;

        return existingGroupObject;
      }

      return group;
    });
  };
  const formatContractsForExpandedRows = (contractData?: ContractDetailInterface[]) => {
    const dataToMap = contractData || contracts.value;
    const parentContracts = dataToMap.filter(
      (contract) =>
        !contract.parent_id ||
        contract.parent_id === contract.id ||
        contract.contract_type.type === CONTRACT_VERSION_TYPE.RENEWAL,
    );
    const treeMapFunc = (contract: ContractDetailInterface): TreeNode => {
      const contractList = contractData || contracts.value;
      let childNodes: ContractDetailInterface[] = [];

      // if for some reason a contract's parent_id is equal to its own id,
      // we don't want to attach it to itself
      childNodes = contractList.filter((contract_1) => {
        if (contract_1.parent_id !== contract_1.id) {
          // New contracts and Renewal contracts are always parent contracts, expansions are the only
          // possible child contract types
          return (
            contract_1.parent_id === contract.id && contract_1.contract_type.type !== CONTRACT_VERSION_TYPE.RENEWAL
          );
        }
      });

      if (childNodes.length) {
        contract.children = childNodes.map(treeMapFunc);
      } else {
        contract.children = [];
      }

      return {
        key: contract.id?.toString(),
        children: contract.children,
        data: { ...contract },
      };
    };

    contractsTableData.value = parentContracts.length ? parentContracts.map(treeMapFunc) : dataToMap.map(treeMapFunc);
  };

  const removeServiceProviderFromContractData = (id: number) => {
    const clinicianToRemove = contract.value.clinicians?.findIndex((clinician) => {
      return clinician.id === id;
    });

    if (Number.isInteger(clinicianToRemove) && (clinicianToRemove as number) > -1) {
      contract.value.clinicians?.splice(clinicianToRemove as number, 1);
    }
  };

  /**
   * a reactive array of all the current and future contracts
   */
  const currentAndFutureContracts = computed(() => {
    const today = new Date().getTime();
    return contracts.value.filter((contract) => new Date(contract.end_at).getTime() > today);
  });

  /**
   * when adding an SP to a contract check the other org contracts and:
   *  IF  they are already HOURLY they cannot be FLAT FEE or PER STUDENT
   *  IF  they are already FLAT FEE or PER STUDENT they cannot be HOURLY
   *  Alabama Destinations Career Academnt  HOURLY -- SP -  Amy Currey
   */
  const serviceProviderIsNotInAnotherContract = (clinicianId: number) => {
    const contractType = contract.value.billing_type;
    let canAdd = true;
    switch (contractType) {
      case CONTRACT_BILLING_TYPE.FLAT_FEE:
      case CONTRACT_BILLING_TYPE.PER_STUDENT:
        currentAndFutureContracts.value.forEach((contract) => {
          if (contract.disciplines.length) {
            const disciplines = contract.disciplines as DisciplineInterface[];
            if ((disciplines[0].contract_type as string) === CONTRACT_BILLING_TYPE.HOURLY) {
              const clinicians = contract?.clinicians?.find((sp: ContractClinicianInterface) => {
                return sp.person?.id === clinicianId;
              });
              if (clinicians) canAdd = false;
            }
          }
        });
        break;
      case CONTRACT_BILLING_TYPE.HOURLY:
        currentAndFutureContracts.value.forEach((contract) => {
          if (contract.disciplines.length) {
            const disciplines = contract.disciplines as DisciplineInterface[];
            if (
              (disciplines[0].contract_type as string) === CONTRACT_BILLING_TYPE.FLAT_FEE ||
              (disciplines[0].contract_type as string) === CONTRACT_BILLING_TYPE.PER_STUDENT
            ) {
              const clinicians = contract?.clinicians?.find((sp: ContractClinicianInterface) => {
                return sp.person?.id === clinicianId;
              });
              if (clinicians) canAdd = false;
            }
          }
        });
        break;
      default:
        return canAdd;
    }
    return canAdd;
  };

  /**
   * The function `getScheduledScrub` asynchronously fetches the schedule scrub data for a specific
   * organization.
   */
  const getScheduledScrub = async () => {
    const response = await OrganizationContractsService.getScheduleScrub(organizationId.value);
    scheduleScrub.value = response.data.data?.schedule_scrub;
    minScheduleScrubDate.value = response.data.data?.min_date;
    maxScheduleScrubDate.value = response.data.data?.max_date;
    isScrubbed.value = response.data.data?.is_scrubbed;
  };

  const postponeScheduleScrub = async (date: string) => {
    await OrganizationContractsService.postponeScheduleScrub(organizationId.value, date);
  };

  return {
    //state
    availableDisciplinesData,
    contract,
    contracts,
    contractsTableData,
    contractTypes,
    editType,
    FILTER,
    loading,
    pendingRequests,
    salesReps,
    scheduleScrub,
    minScheduleScrubDate,
    maxScheduleScrubDate,
    isScrubbed,
    //getters
    canEditOrCreateContracts,
    organizationId,
    organizationName,
    selectedContractStarted,
    //actions
    addServiceProviderToPendingRequests,
    checkDateRangeEventRemoval,
    createContract,
    deleteDiscipline,
    deleteServiceProvider,
    doPendingRequests,
    expandOrganizationContract,
    filterContracts,
    getContracts,
    getContractTypes,
    getDisciplineEventCount,
    getMultiDisciplineData,
    getNewContractTemplate,
    getOrganizationContract,
    getSalesReps,
    getServiceProviderEventCount,
    removeServiceProviderFromContractData,
    renewOrganizationContracts,
    restoreDiscipline,
    restoreServiceProvider,
    save,
    serviceProviderIsNotInAnotherContract,
    showContract,
    getScheduledScrub,
    postponeScheduleScrub,
  };
});
